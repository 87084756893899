import { useState } from "react";
import { get } from "../api/apis";

export const usePrevision = () => {
  const [listadoAfp, setListadoAfp] = useState([]);
  const [listadoPrevision, setListadoPrevision] = useState([]);

  const getListaAfp = async (api, params) => {
    if (params.jubilado === '') {
      setListadoAfp([]);
    } else {
      const resp = await get(api, params);
      setListadoAfp(resp.data);
    }
  }

  const getListaSalud = async (api, params) => {
    const resp = await get(api, params);
    setListadoPrevision(resp.data.data);
  }

  return { getListaAfp, getListaSalud, listadoAfp, listadoPrevision }
}