import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { obtenerListaVacantesAprobadas } from '../api/contratacion/solicitudVacanteApi';
import Spinner from './ui/Spinner';
import DataTable from 'react-data-table-component';
import { customStyles } from '../utils/Constants';

const ListadoVacantesAprobadasScreen = () => {
  const navigate = useNavigate()
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [pageTable, setPageTable] = useState(1)

  useEffect(() => {
    fetchUsers(pageTable);
  }, []);


  const fetchUsers = async (page) => {
    setLoading(true);
    const data = await obtenerListaVacantesAprobadas(page, perPage)
    setLoading(false);

    if (data.status === 'success') {
      const dataTable = data.data.map((vacante) => {
        const button = [<button className="btn btnAccion rounded-3 btn-primary btn-sm" data-bs-toggle="tooltip" title="Ver solicitud" onClick={() => acccionVerVacante(vacante.id, vacante.id_persona)}><FontAwesomeIcon icon={faEye} title='Ver solicitud' /></button>]
        return {
          nombre: vacante.nombre,
          identificacion: vacante.identificacion,
          nombre_vacante: vacante.nombre_vacante,
          fecha_ingreso: vacante.fecha_ingreso,
          acciones: button
        }
      })
      setData(dataTable);
      setTotalRows(data.paginacion.total);
    }
  };

  const handlePageChange = page => {
    setPageTable(page)
    fetchUsers(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPerPage(newPerPage);
    fetchUsers(page)
  };

  const columns = [
    {
      name: 'Nuevo colaborador',
      sortable: true,
      selector: row => row.nombre,
    },
    {
      name: 'Rut',
      sortable: true,
      selector: row => row.identificacion,
    },
    {
      name: 'Cargo',
      sortable: true,
      selector: row => row.nombre_vacante,
    },
    {
      name: 'Fecha Ingreso	',
      sortable: true,
      selector: row => row.fecha_ingreso,
    },
    {
      name: 'Acciones',
      sortable: true,
      button: true,
      cell: row => row.acciones,
    }
  ];


  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };

  const acccionVerVacante = async (idSolicitud, idPersonaSolicitud) => {
    return navigate(`/detalle-vacante/${idSolicitud}`, { state: { idSolicitud, idPersonaSolicitud } });
  }

  return (
    <div className="row">
      <div className="col-sm-12 ">
        <div className="card">
          <div className="card-header bgArcopime h5 py-1">Solicitudes aprobadas</div>
          {
            loading
              ? (<Spinner />)
              : (
                <div className="card-body contenedorTabla">
                  {data.length > 0
                    ?
                    (
                      <DataTable
                        title={false}
                        responsive={true}
                        columns={columns}
                        data={data}
                        progressPending={loading}
                        pagination
                        paginationServer
                        paginationDefaultPage={pageTable}
                        paginationPerPage={perPage}
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        customStyles={customStyles}
                        paginationComponentOptions={paginationComponentOptions}
                        dense
                        fixedHeader
                        fixedHeaderScrollHeight="480px"
                      />
                    )
                    :
                    <p className='h5 text-center my-3 titleMensajeSimple'>No se encontraron registros</p>
                  }
                </div>
              )
          }
        </div>
      </div>
    </div>
  );
};

export default ListadoVacantesAprobadasScreen;
