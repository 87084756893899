import { useState } from "react";

export const useDatosDireccion = () => {
  let errObj = {};
  const [datosDireccionErr, setErrors] = useState({});

  const validateDireccion = obj => {
    const { calle, numeroDepartamento, region, comuna, tipoDireccion, numeroCasa } = obj;

    if (!tipoDireccion) { errObj.tipoDireccion = "Requerido"; }

    if (!calle) { errObj.calle = "Requerido"; }

    if (numeroDepartamento && numeroDepartamento < 0) { errObj.numeroDepartamento = "Ingrese un número válido"; }

    if (!region) { errObj.region = "Requerido"; }

    if (!comuna) { errObj.comuna = "Requerido"; }

    if (!numeroCasa) { errObj.numeroCasa = "Requerido"; }

    setErrors(errObj);
  }

  return { datosDireccionErr, validateDireccion }
}