import { Formik } from "formik";
import moment from "moment";
import { DOMINIOS_VALIDOS } from "../../utils/Constants";
import { mensajeSimple } from "../../components/ui/sweetAlerts";

export const validate = (values) => {
    let errors = {
        datosLaborales: {},
        datosPersonales: {},
        datosPersonaExtranjera: {},
        datosBancarios: {},
        datosDireccion: {},
        datosPrevision: {},
        datosDocumentos: {},
    };

    const { fechaIngreso, cargo, planHorario, tipoContrato } = values.datosLaborales;

    if (!fechaIngreso) {
        errors.datosLaborales.fechaIngreso = "Requerido";
    }

    if (moment(fechaIngreso).format('MM') < moment().format('MM')) {
        errors.datosLaborales.fechaIngreso = "No se puede seleccionar un mes anterior";
    }

    if (moment(fechaIngreso).format('MM') >= moment().format('MM') && moment(fechaIngreso) < moment().subtract(5, 'days')) {
        errors.datosLaborales.fechaIngreso = "No se puede ingresar una fecha con más de 5 días de retraso";
    }

    if (!cargo) {
        errors.datosLaborales.cargo = "Requerido";
    }

    if (!planHorario) {
        errors.datosLaborales.planHorario = "Requerido";
    }

    if (!tipoContrato) {
        errors.datosLaborales.tipoContrato = "Requerido";
    } else if (tipoContrato && !fechaIngreso) {
        errors.datosLaborales.tipoContrato = "Es necesario la fecha de ingreso";
    }

    // datos personales
    const {
        nombres,
        apellidoPaterno,
        apellidoMaterno,
        fechaNacimiento,
        sexo,
        nacionalidad,
        email,
        telefonoCelular,
    } = values.datosPersonales;

    if (!nombres) {
        errors.datosPersonales.nombres = "Requerido";
    }

    if (!apellidoPaterno) {
        errors.datosPersonales.apellidoPaterno = "Requerido";
    }
    if (!apellidoMaterno) {
        errors.datosPersonales.apellidoMaterno = "Requerido";
    }

    if (!fechaNacimiento) {
        errors.datosPersonales.fechaNacimiento = "Requerido";
    }

    if (moment(fechaIngreso).diff(moment(fechaNacimiento), "years") < 18) {
        errors.datosPersonales.fechaNacimiento = "La persona debe ser mayor de 18 años.";
    }

    if (!sexo) {
        errors.datosPersonales.sexo = "Requerido";
    }

    if (!nacionalidad) {
        errors.datosPersonales.nacionalidad = "Requerido";
    }

    if (!email) {
        errors.datosPersonales.email = "Requerido";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        errors.datosPersonales.email = "Direccion de correo invalida.";
    }
    else if (DOMINIOS_VALIDOS.includes(email.split("@")[1])) {
        errors.datosPersonales.email = "El dominio del correo es inválido";
    }

    if (telefonoCelular.length < 8 || telefonoCelular.length > 8) {
        errors.datosPersonales.telefonoCelular = "debe ingresar 8 digitos.";
    }

    //datos de personal extranjero
    const { tipoVisa, visaTemporariaDesde, visaTemporariaHasta } =
        values.datosPersonaExtranjera;

    if (nacionalidad !== "1" && !tipoVisa) {
        errors.datosPersonaExtranjera.tipoVisa = "Requerido";
    }

    if (nacionalidad !== "1" && tipoVisa === "2" && !visaTemporariaDesde) {
        errors.datosPersonaExtranjera.visaTemporariaDesde = "Requerido";
    }

    if (nacionalidad !== "1" && tipoVisa === "2" && !visaTemporariaHasta) {
        errors.datosPersonaExtranjera.visaTemporariaHasta = "Requerido";
    }

    //datos direccion
    const { calle, numeroCasa, numeroDepartamento, region, comuna, tipoDireccion } =
        values.datosDireccion;

    if (!tipoDireccion) {
        errors.datosDireccion.tipoDireccion = "Requerido";
    }

    if (!calle) {
        errors.datosDireccion.calle = "Requerido";
    }

    if (!numeroCasa) {
        errors.datosDireccion.numeroCasa = "Requerido";
    } else if (numeroCasa < 0) {
        errors.datosDireccion.numeroCasa = "Ingrese un numero válido";
    }

    if (numeroDepartamento && numeroDepartamento < 0) {
        errors.datosDireccion.numeroDepartamento = "Ingrese un numero válido";
    }

    if (!region) {
        errors.datosDireccion.region = "Requerido";
    }

    if (!comuna) {
        errors.datosDireccion.comuna = "Requerido";
    }

    //datos prevision
    const { jubilado, previsionSalud, previsionSocial, planPactado } =
        values.datosPrevision;
    if (!jubilado) {
        errors.datosPrevision.jubilado = "Requerido";
    }
    if (!previsionSalud) {
        errors.datosPrevision.previsionSalud = "Requerido";
    }
    if (!previsionSocial) {
        errors.datosPrevision.previsionSocial = "Requerido";
    }

    if (previsionSalud !== "1" && !planPactado) {
        errors.datosPrevision.planPactado = "Requerido";
    }

    const regexp = /^\d+(\.\d{1,4})?$/;
    if (previsionSalud !== "1" && isNaN(planPactado)) {
        errors.datosPrevision.planPactado =
            "Ingrese corretamente el valor de la UF";
    } else if (planPactado && !regexp.test(planPactado)) {
        errors.datosPrevision.planPactado = "Deben tener entre 1 a 4 decimales";
    }

    //datos bancarios
    const { formaDePago, tipoCuenta, banco, numeroCuenta } =
        values.datosBancarios;
    if (!formaDePago) {
        errors.datosBancarios.formaDePago = "Requerido";
    }

    if (formaDePago === "2" && !banco) {
        errors.datosBancarios.banco = "Requerido";
    }

    if (formaDePago === "2" && !tipoCuenta) {
        errors.datosBancarios.tipoCuenta = "Requerido";
    }

    if (formaDePago === "2" && !numeroCuenta) {
        errors.datosBancarios.numeroCuenta = "Requerido";
    }

    //datos de documentos
    // const {
    //   curriculum,
    //   cedula,
    //   antecedentes,
    //   residencia,
    //   afp,
    //   salud,
    //   visa,
    //   jubilacion,
    // } = values.datosDocumentos;

    // const arrTipoDocumentosGeneral = ['application/pdf', 'image/jpeg', 'image/png']
    // const arrTipoDocumentoResidencial = [...arrTipoDocumentosGeneral, 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']


    // if (curriculum === null || curriculum.length === 0) {
    //   errors.datosDocumentos.curriculum = "Requerido";
    // }

    // if (curriculum && !arrTipoDocumentosGeneral.includes(curriculum[0].type)) {
    //   errors.datosDocumentos.curriculum = "El formato del documento debe ser .pdf, .png o .jpg";
    // }

    // if (cedula === null || cedula.length === 0) {
    //   errors.datosDocumentos.cedula = "Requerido";
    // }

    // if (cedula && !arrTipoDocumentosGeneral.includes(cedula[0].type)) {
    //   errors.datosDocumentos.cedula = "El formato del documento debe ser .pdf, .png o .jpg";
    // }

    // if (antecedentes === null || antecedentes === 0) {
    //   errors.datosDocumentos.antecedentes = "Requerido";
    // }

    // if (antecedentes && !arrTipoDocumentosGeneral.includes(antecedentes[0].type)) {
    //   errors.datosDocumentos.antecedentes = "El formato del documento debe ser .pdf, .png o .jpg";
    // }

    // if (residencia === null || residencia === 0) {
    //   errors.datosDocumentos.residencia = "Requerido";
    // }

    // if (residencia && !arrTipoDocumentoResidencial.includes(residencia[0].type)) {
    //   errors.datosDocumentos.residencia = "El formato del documento debe ser .pdf, .docx , .png o .jpg";
    // }


    // if (afp === null || afp === 0) {
    //   errors.datosDocumentos.afp = "Requerido";
    // }

    // if (afp && !arrTipoDocumentosGeneral.includes(afp[0].type)) {
    //   errors.datosDocumentos.afp = "El formato del documento debe ser .pdf, .png o .jpg";
    // }

    // if (salud === null || salud === 0) {
    //   errors.datosDocumentos.salud = "Requerido";
    // }

    // if (salud && !arrTipoDocumentosGeneral.includes(salud[0].type)) {
    //   errors.datosDocumentos.salud = "El formato del documento debe ser .pdf, .png o .jpg";
    // }

    // // validar certificado de visa
    // if (tipoVisa === "2" && !visa) {
    //   errors.datosDocumentos.visa = "Requerido";
    // }

    // if (tipoVisa === "2" && !arrTipoDocumentosGeneral.includes(tipoVisa[0].type)) {
    //   errors.datosDocumentos.tipoVisa = "El formato del documento debe ser .pdf, .png o .jpg";
    // }

    // // validar certificado dejubilacion
    // if (jubilado === "1" && jubilacion === null) {
    //   errors.datosDocumentos.jubilacion = "Requerido";
    // }

    // if (jubilado === "1" && !arrTipoDocumentosGeneral.includes(jubilado[0].type)) {
    //   errors.datosDocumentos.jubilado = "El formato del documento debe ser .pdf, .png o .jpg";
    // }


    const noHayErrores = Object.values(errors).every(el => Object.values(el).length === 0);
    errors = noHayErrores ? Formik.setErrors({}) : errors

    return errors;
};

export const fileSize = (size) => {
    if (size <= 10485760) { return true; } //20971520 = 20Mb ------ 124288 = prueba
    else {
        mensajeSimple('info', 'Archivo muy pesado', 2500);
        return false;
    }
}

export const validarRespPeticion = (resp, mensaje = "Operación exitosa") => {
    if (resp.data && resp.data.status === "success") { mensajeSimple(resp.data.status, resp.data.message = mensaje, 3000); return true; }
    else { mensajeSimple('error', '¡Ocurrio un problema!'); return false; }
}