import { momentFecha } from "../utils/funciones";
import moment from "moment";
const exportarJsonExcel = require("js-export-excel");

export const useExcel = () => {

  const generarExcel = async props => {
    const { arrData, arrHeaders, sheetHeadersArr, sheetColumnWidth, fileName = 'tabla' } = props;
    const fecha = new Date();

    let sheetConfig = {
      fileName: `${fileName}__${momentFecha(fecha)}__${moment(fecha).format("HHmm")}`,
      datas: [
        {
          sheetHeader: sheetHeadersArr,
          sheetData: arrData,
          sheetName: `hoja1`,
          sheetFilter: arrHeaders,
          columnWidths: sheetColumnWidth,
        },
      ],
    }

    let excelFile = new exportarJsonExcel(sheetConfig);
    await excelFile.saveExcel();
  }

  return {
    generarExcel
  }
}