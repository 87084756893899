// Importando bibliotecas y componentes necesarios
// import DataTable from 'react-data-table-component';
import DataTable, { defaultThemes } from 'react-data-table-component';
import { useEffect, useState } from 'react';

// Componentes y utils generales
import { mensajeSimple, modalCargando } from '../../../components/ui/sweetAlerts';
import { objStyleCol } from '../../../utils/Constants';
import { sumaStatusContrato } from '../../../utils/funciones';
import { momentFecha, formatDate } from '../../../utils/funciones';
import Spinner from '../../../components/ui/Spinner';
import { InputBusqueda } from "../../../components/InputBusqueda";
import { BtnDescargarExcel } from '../../../components/BtnDescargarExcel';


// Funciones de conexión con APIs
import { get, getUno, post } from '../../../api/apis';

const traerColor = (estado) => {
    let color = '';
    switch (estado) {
        case 'Aprobado':
            color = 'success'
            break;
        case 'Rechazado':
            color = 'orange'
            break;
        case 'En Espera':
            color = 'warning'
            break;
        default:
            color = 'transparent'
            break;
    }
    return color;
}

const centrado = (nombre) => {
    if (nombre === '---') {
        return 'text-center'
    }
    return ''

}


const HtmlValidacion = ({ props }) => {
    return (
        <>
            <span className={`${centrado(props.nombre)}`}>{props.nombre}</span>
            <div
                className={`bg-${traerColor(props.nombre)} position-absolute`}
                style={{
                    height: '25px',
                    width: '25px',
                    borderRadius: '50%',
                    right: '3px',
                    top: '3px',
                }}
            >
            </div>
        </>
    )
}

const Monitor = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [contratosArray, setContratosArray] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [pageTable, setPageTable] = useState(1);
    const [dataMonitor, setDataMonitor] = useState([]);

    const [filtro2, setFiltro2] = useState('');

    const [listaBusqueda, setListaBusqueda] = useState([]);
    const [filterArr, setFilterArr] = useState([]);
    const [dataMonitorExcel, setDataMonitorExcel] = useState([]);

    const reenviarContrato = async (id) => {
        modalCargando('Reenviando');
        const resp = await post('reenvio_contrato', { id_contrato: id })
        console.log({ resp });

        if (resp.data.status === 'info') { mensajeSimple('info', resp.data.message, 4500); }
        else if (resp.data.status === 'success') {
            mensajeSimple(resp.data.status, resp.data.message, 4000);
            llamarPagina()
            // setTimeout(() => {
            //   localStorage.setItem('page', parseInt(page) + 1);
            //   setearPagina((currentPage) => currentPage + 1);
            //   setBloqueado(!bloqueado);
            // }, 5000);
        }
        else { mensajeSimple('error', 'Ha ocurrido un error.'); }
    }

    const BtnReenvio = ({ dato, id }) => {
        return (
            <>
                {dato === '1'
                    ? <button onClick={() => { reenviarContrato(id) }} className='btn btn-primary btn-sm'>Reenviar</button>
                    : null
                }
            </>
        )
    }

    const compareStatusContratoColaborador = (a, b) => {
        const getValue = (row) => {
            // Obtener el valor del estado del contrato o el valor predeterminado
            return row.status_contrato[2] ? row.status_contrato[2].estado_validador : { "nombre": "Espera Aprobación Admnistrador" };
        };

        // Obtener los valores a comparar
        const valueA = getValue(a);
        const valueB = getValue(b);

        // Comparar los valores (puedes personalizar esto según tus necesidades)
        return valueA.nombre.localeCompare(valueB.nombre);
    };
    const compareStatusContratoAdministrador = (a, b) => {
        const getValue = (row) => {
            // Obtener el valor del estado del contrato o el valor predeterminado
            return row.status_contrato[1] ? row.status_contrato[1].estado_validador : { "nombre": "Espera Aprobación Jefe Remuneraciones" };
        };

        // Obtener los valores a comparar
        const valueA = getValue(a);
        const valueB = getValue(b);

        // Comparar los valores (puedes personalizar esto según tus necesidades)
        return valueA.nombre.localeCompare(valueB.nombre);
    }

    const columnas = {
        admin: [
            {
                name: 'Id envío',
                sortable: false,
                selector: row => row.IdLote,
                width: '95px',
                style: objStyleCol
            },
            {
                name: 'N° Personal',
                sortable: false,
                selector: row => row.NPersonal,
                width: '85px',
                style: objStyleCol
            },
            {
                name: 'Nombre colaborador',
                sortable: false,
                selector: row => row.Nombre,
                wrap: true,
                minWidth: '300px',
            },
            {
                name: 'Rut',
                sortable: false,
                selector: row => row.Rut,
                width: '100px',
                style: objStyleCol
            },
            {
                name: 'División',
                sortable: false,
                selector: row => row.Division,
                width: '70px',
                style: objStyleCol
            },
            {
                name: 'Unidad organizativa',
                sortable: false,
                wrap: true,
                selector: row => row.TextoUnidadOrganizativa,
                minWidth: '140px',
            },
            {
                name: 'Subdivisión',
                sortable: false,
                selector: row => row.Subdivision,
                width: '100px',
                style: objStyleCol
            },
            {
                name: 'Cargo',
                sortable: false,
                selector: row => row.Cargo,
                wrap: true,
                width: '150px',
            },
            {
                name: 'Fecha ingreso',
                sortable: false,
                selector: row => row.FechaIngreso.replace(/\./g, '-'),
                wrap: true,
                width: '90px',
                style: objStyleCol
            },
            {
                name: 'Emisión contrato',
                sortable: false,
                selector: row => row.FechaEmisionContrato.replace(/\./g, '-'),
                wrap: true,
                width: '90px',
                style: objStyleCol
            },
            {
                name: 'Id interno Webdox',
                sortable: false,
                selector: row => row.id_wf ? row.id_wf : '---',
                wrap: true,
                width: '90px',
                style: objStyleCol
            },
            {
                name: 'Validación jefe remuneraciones',
                sortable: false,
                selector: row => row.status_contrato[0] ? <HtmlValidacion props={row.status_contrato[0].estado_validador} /> : '',
                width: '140px',
                // sortFunction: (a, b) => a.status_contrato[0].estado_validador.nombre.localeCompare(b.status_contrato[0].estado_validador.nombre)
            },
            {
                name: "Validación Jefe Inmediato",
                sortable: false,
                selector: row => row.status_contrato[1] ? <HtmlValidacion props={row.status_contrato[1].estado_validador} /> : <HtmlValidacion props={{ "nombre": "" }} />,
                width: '140px',
                wrap: true,
                // sortFunction: compareStatusContratoAdministrador,
            },
            {
                name: "Colaborador",
                sortable: false,
                selector: row => row.status_contrato[2] ? <HtmlValidacion props={row.status_contrato[2].estado_validador} /> : <HtmlValidacion props={{ "nombre": "" }} />,
                width: '120px',
                wrap: true,
                // sortFunction: compareStatusContratoColaborador,
            },
            {
                name: '% Avance',
                sortable: false,
                selector: row => sumaStatusContrato(row.status_contrato),
                width: '70px',
            },
            {
                name: 'Recordatorio',
                sortable: false,
                selector: row => <BtnReenvio dato={row.reenvio_contrato} id={row.id} />,
                width: '130px',
                style: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }
            },
        ]
    }



    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const seleccionarContrato = (obj) => {

        setContratosArray(obj.selectedRows);
    }

    const llamarPagina = async (pagina = 1, totalporpagina = 10, filtro = '', campoordenar = '', orden = '') => {
        setLoading(true);
        //Aqui  me falta definir si externalizar el state del filtro para no tener que buscar el valor del buscador por cada llamado o dejarlo asi mas independiente\
        const resp2 = await getUno('monitorcentral', {
            "page": pagina,
            "total_page": totalporpagina,
            'filtro': filtro,
            'campofiltro': campoordenar,
            'direccion': orden
        }
            , null);
        setTotalRows(resp2.data.registros_totales)
        setDataMonitor(resp2.data.data);
        setLoading(false);
    }

    const mostrarPDF = (strBase64) => {
        const byteString = window.atob(strBase64);
        const int8Array = new Uint8Array(new ArrayBuffer(byteString.length));
        for (let i = 0; i < byteString.length; i++) { int8Array[i] = byteString.charCodeAt(i); }
        const blob = new Blob([int8Array], { type: 'application/pdf' });
        window.open(URL.createObjectURL(blob), '_blank');
    }

    const handlePageChange = page => {
        setPageTable(page);
        llamarPagina(page, perPage, filtro2);
        //setLoading(false);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        llamarPagina(page, newPerPage, filtro2);
    };

    useEffect(() => { llamarPagina(); }, []);

    useEffect(() => {
        const obtenerDataExcel = async () => {
            setLoading(true);
            try {
                const resp = await getUno('descarga_excel_monitor', null, null);
                const data = resp.data.data;
                setDataMonitorExcel(data);
                setLoading(false);
            } catch (error) {
                console.error("Error al obtener los datos:", error);
                setLoading(false);
            }
        };

        obtenerDataExcel();
    }, []);

    const customStyles = {
        tableBody: {
            style: {
                borderLeftStyle: 'solid',
                borderLeftWidth: '1px',
                borderLeftColor: defaultThemes.default.divider.default,
            },
        },
        header: {
            style: {
                minHeight: '40px',
            },
        },
        headRow: {
            style: {
                borderTopStyle: 'solid',
                borderTopWidth: '1px',
                borderTopColor: defaultThemes.default.divider.default,
            },
        },
        headCells: {
            style: {
                '&:not(:last-of-type)': {
                    borderRightStyle: 'solid',
                    borderRightWidth: '1px',
                    borderRightColor: defaultThemes.default.divider.default,
                },
                marginLeft: '2px',
                marginRight: '2px',
            },
        },
        cells: {
            style: {
                '&:not(:last-of-type)': {
                    borderRightStyle: 'solid',
                    borderRightWidth: '1px',
                    borderRightColor: defaultThemes.default.divider.default,
                },
                marginLeft: '2px',
                marginRight: '2px',
            },
        },
        responsiveWrapper: {
            style: {
                border: `1px solid ${defaultThemes.default.divider.default}`,
            }
        }
    };

    const filtrar = (dataMonitor, txt, propsArray) => {
        // Convertir el texto de búsqueda a minúsculas.
        let txtLower = txt.toLowerCase();

        const regexFecha = /^(0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-\d{4}$/;
        if (regexFecha.test(txtLower)) {
            txtLower = txtLower.replace(/-/g, '.');
        }

        // Filtra la lista usando las propiedades especificadas.
        const listaFiltrada = dataMonitor.filter(el =>
            propsArray.some(prop =>
                el[prop] && el[prop].toLowerCase().includes(txtLower)
            )
        );

        return listaFiltrada;
    }


    const handleSort = (column, sortDirection) => {
        //Primero tengo que agregarle guion bajo a valor de la columna,sustituir el espacio por guion bajo y convertirlo a minusculas
        const columna = column.name.replace(/ /g, '_');

        const jsonvalores = {
            "Id_envío": "IdLote",
            "N°_Personal": "NPersonal",
            "Nombre_colaborador": "Nombre",
            "Rut": "Rut",
            "División": "Division",
            "Unidad_organizativa": "TextoUnidadOrganizativa",
            "Subdivisión": "Subdivision",
            "Cargo": "Cargo",
            "Plantilla": "NombrePlantilla",
            "Fecha_ingreso": "FechaIngreso",
            "Emisión_contrato": "FechaEmisionContrato",

        }

        const valor = jsonvalores[columna];

        // llamarPagina(pageTable,perPage,filtro2,valor,sortDirection);

        return


    };



    const acondicionarLista = (lista, excludedKeysArray) => {
        if (lista.length > 0) {
            // Mapea la lista omitiendo las propiedades especificadas en excludedKeysArray.
            const listaAcond = lista.map(item => {
                let obj = {};
                for (let key in item) {
                    if (!excludedKeysArray.includes(key)) {
                        obj[key] = item[key];
                    }
                }
                return obj;
            });

            const filterArr = Object.keys(listaAcond[0]);

            return { listaAcond, filterArr };
        }

        return { listaAcond: [], filterArr: [] };
    }

    const calcularPorcentajeAvance = (status_contrato) => {
        let porcentajeAvance = 0;
        let aprobados = 0;

        for (let i = 0; i < status_contrato.length; i++) {
            const estado = status_contrato[i]?.estado_validador?.nombre || 'N/A';

            if (estado === 'Aprobado') {
                aprobados++;
            } else if (estado === 'Rechazado') {
                break;
            }
        }

        porcentajeAvance = (aprobados / 3) * 100;

        if (aprobados === 3) {
            porcentajeAvance = 100;
        }

        return porcentajeAvance.toFixed(2) + '%';
    };

    const manejarFiltrar = (txt) => {
        setFiltro2(txt)
        llamarPagina(1, perPage, txt)
    }

    const acondicionarDatos = () => {
        const nombreArchivo = `ReporteDataMonitor_${new Date().toISOString().slice(0, 10)}`;
        const mapeoColumnas = {
            'IdLote': 'ID ENVÍO',
            'NPersonal': 'N° PERSONAL',
            'Nombre': 'NOMBRE COLABORADOR',
            'Rut': 'RUT',
            'Division': 'DIVISION',
            'TextoUnidadOrganizativa': 'UNIDAD ORGANIZATIVA',
            'Subdivision': 'SUBDIVISION',
            'OBSERVACIONES': 'OBSERVACIONES',
            'Cargo': 'CARGO',
            'NombrePlantilla': 'PLANTILLA',
            'FechaIngreso': 'FECHA DE INGRESO',
            'FechaEmisionContrato': 'EMISION CONTRATO',
            'id_wf': 'ID INTERNO WEBDOX',
            // Aqui se pueden añadir mas columnas de ser necesario
        };

        const nombresEstado = ['VALIDACION JEFE REMUNERACIONES', 'VALIDACIÓN JEFE INMEDIATO', 'COLABORADOR'];
        const columNombresTabla = [...Object.values(mapeoColumnas), ...nombresEstado, 'PORCENTAJE AVANCE'];

        // Asumir un ancho de columna basado en el nombre más un espacio adicional
        const anchoColumnas = columNombresTabla.map(nombre => nombre.length + 5);

        // Filtramos previamente los datos que tienen un porcentaje de avance del 100%
        const datosFiltrados = dataMonitorExcel.filter(row => {
            const porcentajeAvance = calcularPorcentajeAvance(row.status_contrato);
            return porcentajeAvance !== '100.00';
        });

        const datosTabla = datosFiltrados.map(row => {
            let nuevoObj = {};
            Object.entries(mapeoColumnas).forEach(([key, value]) => {
                nuevoObj[value] = row[key] || '';
            });

            let porcentajeAvance = calcularPorcentajeAvance(row.status_contrato);
            nuevoObj['PORCENTAJE AVANCE'] = `${porcentajeAvance}%`;

            nombresEstado.forEach((estado, i) => {
                nuevoObj[estado] = row.status_contrato && row.status_contrato[i]
                    ? row.status_contrato[i].estado_validador.nombre
                    : 'N/A';
            });

            if (row.status_contrato && row.status_contrato.length) {
                // Se obtiene la ultima observacion de status_contrato
                let ultimoStatus = row.status_contrato[row.status_contrato.length - 1];
                nuevoObj['OBSERVACIONES'] = ultimoStatus.observaciones || 'Sin Observaciones';
            } else {
                nuevoObj['OBSERVACIONES'] = 'Sin Observaciones';
            }

            return nuevoObj;
        });

        const columMuestreo = columNombresTabla;

        return {
            nombreArchivo,
            columNombresTabla,
            datosTabla,
            columMuestreo,
            anchoColumnas
        };
    };



    const [expandedRows, setExpandedRows] = useState([]);

    const handleRowClicked = row => {

        setExpandedRows(prevState => {
            if (prevState.includes(row.id)) {
                return prevState.filter(id => id !== row.id);
            } else {
                return [...prevState, row.id];
            }
        });
    };

    const renderExpandableContent = row => {
        return (
            <div className={`row m-2 details-contract-${row.id}`}>
                <p style={{ marginBottom: '2px' }}><strong>Email Colaborador: </strong> {row.data.EmailColaborador}</p>
                <p style={{ marginBottom: '2px' }}><strong>En curso desde: </strong> {momentFecha(row.data.created_at)} </p>
                <p style={{ marginBottom: '2px' }}><strong>Recordatorio disponible: </strong> {row.data.date_reminder ? formatDate(row.data.date_reminder) : 'Aún no procesado en WebDox'} </p>
                <p style={{ marginBottom: '2px' }}><strong>documentos:</strong></p>
                <ul style={{ marginLeft: '20px' }}>
                    {typeof row.data.documentos !== 'undefined' && row.data.documentos.length > 0
                        ? row.data.documentos.map((doc, i) => (
                            <li key={i}>
                                {doc}
                            </li>
                        ))
                        : <li>No hay documentos disponibles</li>
                    }
                </ul>
            </div>
        );
    };

    const expandedComponent = (data) => renderExpandableContent(data);



    return (
        <>
            <div className="row">
                <div className="col-sm-12 ">
                    <div className="card cardPestania">
                        <div className="card-header bgArcopime h5 d-flex justify-content-between" style={{ padding: '6px 8px 10px 8px' }}>
                            Monitor
                            <InputBusqueda ejecutar={manejarFiltrar} />

                        </div>
                        {
                            loading
                                ? (<Spinner />)
                                : (
                                    <div className="card-body contenedorTabla">
                                        {dataMonitor.length > 0
                                            ?
                                            (
                                                <DataTable
                                                    title={false}
                                                    responsive={true}
                                                    columns={columnas.admin}
                                                    data={listaBusqueda.length > 0 ? listaBusqueda : dataMonitor}
                                                    progressPending={loading}
                                                    pagination
                                                    paginationServer
                                                    paginationDefaultPage={pageTable}
                                                    paginationPerPage={perPage}
                                                    paginationTotalRows={totalRows}
                                                    onChangeRowsPerPage={handlePerRowsChange}
                                                    onChangePage={handlePageChange}
                                                    paginationComponentOptions={paginationComponentOptions}
                                                    customStyles={customStyles}
                                                    onSelectedRowsChange={seleccionarContrato}
                                                    dense
                                                    fixedHeader
                                                    fixedHeaderScrollHeight="450px"
                                                    onSort={handleSort}
                                                    onRowDoubleClicked={handleRowClicked}
                                                    expandableRows
                                                    expandableRowsComponent={expandedComponent}
                                                    expandOnRowClicked
                                                    expandableRowsHideExpander
                                                />
                                            )
                                            :
                                            <p className='h5 text-center my-3 titleMensajeSimple'>No se encontraron registros</p>
                                        }
                                    </div>
                                )
                        }
                    </div>

                </div>
            </div>
            {!loading && dataMonitorExcel.length > 0 && (
                <div className="row mt-3">
                    <div className="col-sm-12">
                        <BtnDescargarExcel ejecutarFn={acondicionarDatos} habilitado={dataMonitorExcel.length > 0} />
                    </div>
                </div>
            )}


        </>
    )
}

export default Monitor;