import { useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';

// Apis
import { getUno } from '../../api/apis';

// Componentes
import Contrato from './contratos/Contrato';
import Monitor from './monitor/Monitor';

const FirmaContrato = () => {
    const [dataMonitor, setDataMonitor] = useState([]);
    const [puedeVerMonitor, setPuedeVerMonitor] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const idUsuarioSAP = localStorage.getItem('id_usuario_sap');
            const resp = await getUno('usuarios_monitor', null, null);
            if (resp && resp.data && resp.data.data) {
                setDataMonitor(resp.data.data); // Guardar los datos en el estado

                console.log(resp.data.data);

                // Comprobamos directamente si el usuario puede ver el tab Monitor
                const puedeVer = resp.data.data.some(user => user.NPersonal === idUsuarioSAP);
                setPuedeVerMonitor(puedeVer);
            }
        };

        fetchData();
    }, []);

    return (
        <Tabs
            defaultActiveKey="home"
            transition={false}
            id="noanim-tab-example"
            className="mb-0"
        >
            <Tab eventKey="home" title="Documentos a revisar">
                <Contrato />
            </Tab>

            {puedeVerMonitor &&
                <Tab eventKey="profile" title="Monitor">
                    <Monitor data={dataMonitor} />
                </Tab>
            }
        </Tabs>
    );
}

export default FirmaContrato;
