import Monitor from '../firmaContrato/monitor/Monitor';

const Index = () => {
    return (
        <div>
            {(localStorage.getItem('id_perfil') === '6') && <Monitor/>}
        </div>
    );
}

export default Index;
