import React, { useEffect, useState, useContext } from 'react'
import { obtenerEstadosCivilesAction, obtenerGenerosAction } from '../redux/datosPersonalesDucks';
import { obtenerPersonaExtranjeraApi } from '../api/contratacion/datosPersonaExtranjeraApi';
import { obtenerPaisesAction, obtenerRegionesAction } from '../redux/datosZonasDucks';
import { obtenerNivelesEducacionalesAction } from '../redux/datosEducacionalesDucks';
import { obtenerDatosPersonalesApi } from '../api/contratacion/datosPersonalesApi';
import { obtenerDatosBancariosApi } from '../api/contratacion/datosBancariosApi';
import { obtenerDatosPrevisioApi } from '../api/contratacion/datosPrevisionApi';
import { obtenerDireccionApi } from '../api/contratacion/datosDireccionApi';
import { obtenerVacanteApi } from '../api/contratacion/solicitudVacanteApi';
import { obtenerDatosAfpAction } from '../redux/datosPrevisionalesDucks';
import { obtenerDocumentoApi } from '../api/contratacion/documentosApi';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { obtenerBancosAction } from '../redux/datosBancariosDucks';
import { obtenerComunasAction } from '../redux/datosZonasDucks';
import { usePrevision } from '../hooks/datosPrevisionales';
import { DataContext } from '../contextData/contextData';
import { useDispatch, useSelector } from 'react-redux';
import { modalCargando } from './ui/sweetAlerts';
import Swal from 'sweetalert2';
import { momentFecha } from '../utils/funciones';
import { get, getUno } from '../api/apis';

const VerVacateScreen = ({ nroPagina }) => {
  const { page, setearPagina } = useContext(DataContext);
  const { state } = useLocation();
  const { idSolicitud, solicitud } = useParams();
  const { getListaAfp, listadoAfp } = usePrevision();
  const [datosLaborales, setDatosLaborales] = useState([])
  const [datosPersonales, setDatosPersonales] = useState([])
  const [datosExtranjero, setDatosExtranjero] = useState([])
  const [datosDireccion, setDatosDireccion] = useState([])
  const [datosPrevision, setDatosPrevision] = useState([])
  const [datosBancarios, setdatosBancarios] = useState([])
  const [datosDocumento, setdatosDocumento] = useState([])
  const [datoSalud, setDatoSalud] = useState({})

  const dispatch = useDispatch()
  const navigate = useNavigate()

  async function getAFP() {
    modalCargando('Obteniendo datos...');
    await obtenerVacanteApi(state.idPersonaSolicitud, state.idSolicitud)
      .then(res => {
        if (res.status === "success") setDatosLaborales(res.data);
      }).catch(error => console.log("Error :", error))

    await obtenerDatosPersonalesApi(state.idPersonaSolicitud)
      .then(res => {
        if (res.status === "success") setDatosPersonales(res.data)
      }).catch(error => console.log("Error :", error))

    await obtenerDireccionApi(state.idPersonaSolicitud)
      .then(res => {
        if (res.status === "success") setDatosDireccion(res.data)
      }).catch(error => console.log("Error :", error))

    await obtenerDatosPrevisioApi(state.idPersonaSolicitud)
      .then(res => {
        if (res.status === "success") {
          setDatosPrevision(res.data);
          getListaAfp('afp', { jubilado: res.data.jubilado });
        }
      }).catch(error => console.log("Error :", error))

    await obtenerPersonaExtranjeraApi(state.idPersonaSolicitud)
      .then(res => {
        if (res.status === "success") setDatosExtranjero(res.data)
      }).catch(error => console.log("Error: ", error))

    await obtenerDatosBancariosApi(state.idPersonaSolicitud)
      .then(res => {
        if (res.status === "success") setdatosBancarios(res.data)
      }).catch(error => console.log("Error :", error))

    await obtenerDocumentoApi(state.idPersonaSolicitud).then(res => {
      if (res.status === "success") setdatosDocumento(res.data)
    }).catch(error => console.log("Error :", error))

    Swal.close();
  }

  const getSalud = async () => {
    const resp = await getUno('personasalud', { idPersona: state.idPersonaSolicitud });
    const respPrevision = await get('prevision', { idPersona: state.idPersonaSolicitud });

    const uno = respPrevision.data.data.find((el) => el.id === resp.data.data.id_institucion_salud)
    setDatoSalud({ ...uno, valorPlan: resp.data.data.valor_plan });
  }

  useEffect(() => {
    getAFP();
    getSalud();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(obtenerEstadosCivilesAction());
      await dispatch(obtenerGenerosAction());
      await dispatch(obtenerNivelesEducacionalesAction());
      await dispatch(obtenerPaisesAction());
      await dispatch(obtenerDatosAfpAction());
      await dispatch(obtenerBancosAction());
      await dispatch(obtenerRegionesAction(datosPersonales.id_pais));
    }
    fetchData();
  }, [dispatch])

  useEffect(() => {
    const getComunas = () => { dispatch(obtenerComunasAction(datosDireccion.id_region)); }
    if (datosDireccion.id_region !== '' && datosDireccion.id_region !== undefined) { getComunas(); }
  }, [datosDireccion.id_region]);


  const comunas = useSelector((store) => store.datosZonas.comunas)
    .filter((el) => el.id === datosDireccion.id_comuna)
    .map(item => item.nombre);

  const filtrarSexo = useSelector(store => store.datosPersonales.generos)
    .filter(sexo => sexo.id === datosPersonales.id_persona_genero)
    .map(item => item.nombre)

  const filtrarEstadoCivil = useSelector(store => store.datosPersonales.estadosCiviles)
    .filter(estadiCivil => estadiCivil.id === datosPersonales.id_persona_estado_civil)
    .map(item => item.nombre)

  const filtrarGradoEducacion = useSelector(store => store.datosEducacionales.nivelesEducacionales)
    .filter(grado => grado.id === datosPersonales.id_educacion_nivel)
    .map(item => item.nombre)

  let filtrarPaisPersona = useSelector(store => store.datosZonas.paises)
    .filter(pais => pais.id === datosPersonales.id_pais)
    .map(item => item.nombre)

  if (filtrarPaisPersona.length > 0) {
    const primera = filtrarPaisPersona[0].substring(0, 4)
    if (primera === '0000') { filtrarPaisPersona = filtrarPaisPersona[0].split(' ')[1] }
  }

  let filtrarPaisDireccion = useSelector(store => store.datosZonas.paises)
    .filter(pais => pais.id === datosDireccion.id_pais)
    .map(item => item.nombre)

  if (filtrarPaisDireccion.length > 0) {
    const primera = filtrarPaisDireccion[0].substring(0, 4)
    if (primera === '0000') { filtrarPaisDireccion = filtrarPaisDireccion[0].split(' ')[1] }
  }

  const filtrarRegion = useSelector(store => store.datosZonas.regiones)
    .filter(region => region.id === datosDireccion.id_region)
    .map(item => item.nombre)

  const filtrarAfp = listadoAfp.filter(afp => afp.id === datosPrevision.id_afp).map(item => item.nombre);

  const verHistorial = () => {
    navigate(`/detalle-vacante/${idSolicitud}`, { state: state });
  }

  const editarVacante = () => {
    navigate(`/editar-vacante/${idSolicitud}`, { state: state });
  }

  const continuarProceso = () => { navigate(`/editar-vacante/${idSolicitud}`, { state: state }) }

  return (
    <div className='mx-5'>
      <div className="row">
        <div className="d-grid gap-2 d-md-flex justify-content-md-end my-2 pe-0">
          {console.log(state.id_estado_solicitud)}
          {(state.solicitud.id_estado_solicitud === 17) ?
            <button className="btn btn-success me-md-2" type="button" onClick={() => continuarProceso()}>Continuar Proceso</button> : null
          }
          
          <button className="btn btn-arcoprime-st me-md-2" type="button" onClick={() => { navigate('/resumen-solicitudes');} }>Volver</button>
          <button className="btn btn-arcoprime-st me-md-2" type="button" onClick={() => verHistorial()}>Ver historial</button>
          {(
            (localStorage.getItem('id_perfil') === "4") && (state.solicitud.id_estado_solicitud === 7 || state.solicitud.id_estado_solicitud === 10) ||
            ((localStorage.getItem('id_perfil') === "3") && state.solicitud.id_estado_solicitud !== 13)
          ) && (
              <> {state.editable && <button className="btn btn-arcoprime-st" type="button" onClick={() => editarVacante()}>Editar Solicitud</button>} </>
            )}
        </div>
        {
          datosLaborales.length !== 0 && (
            <div className="card my-3 px-0">
              <div className="card-header bgArcopime"><label className='h6 my-0'>Datos laborales</label></div>
              <div className="card-body">
                <div className="row">
                  <p className='col-4 mb-1'>
                    <span className='tagLabel'>Cargo a Ocupar:</span> {`${datosLaborales.codigo_sap}  ${datosLaborales.nombre_vacante}`}
                  </p>
                  <p className='col-4 mb-1'>
                    <span className='tagLabel me-1'>Tipo contrato:</span>
                    {
                      (datosLaborales.tipo_contrato === 1 && ("Plazo Fijo 30 Días")) ||
                      (datosLaborales.tipo_contrato === 2 && ("Plazo Fijo 60 Días")) ||
                      (datosLaborales.tipo_contrato === 3 && ("Plazo Fijo 90 Días")) ||
                      (datosLaborales.tipo_contrato === 4 && ("Indefinido"))
                    }
                  </p>
                  <p className='col-4 mb-1'>
                    <span className='tagLabel'>Unidad de temporis:</span> {
                      datosLaborales.temporis_unidad_rol.data !== ''
                        ? JSON.parse(datosLaborales.temporis_unidad_rol.data).Unidadorganizativa
                        : 'No aplica'
                    }
                  </p>
                </div>
                <div className="row">
                  <p className='col-4 mb-1'>
                    <span className='tagLabel'>Inicio solicitud:</span> {datosLaborales.inicio_solicitud}
                    {/* <span className='tagLabel'>Inicio solicitud:</span> {momentFecha(datosLaborales.fecha_ingreso)} */}
                  </p>
                  <p className='col-4 mb-1'><span className='tagLabel'>Ingreso:</span> {momentFecha(datosLaborales.fecha_ingreso)}</p>
                  {/* <p className='col-4 mb-1'><span className='tagLabel'>Ingreso:</span> {datosLaborales.inicio_solicitud}</p> */}
                  <p className='col-4 mb-1'>
                    <span className='tagLabel me-1'>Término:</span>
                    {datosLaborales.tipo_contrato !== 4 ? momentFecha(datosLaborales.fecha_retiro) : 'Plazo indefinido'}
                  </p>
                </div>
                <div className="row">
                  <p className='col-4 mb-1'>
                    <span className='tagLabel'>Horario de temporis:</span> {datosLaborales.temporis_plan_horario.data}
                  </p>
                  <p className='col-8 mb-1'>
                    <span className='tagLabel me-1'>Nombre local:</span>
                    {datosLaborales.nombre_local || 'No aplica'}
                  </p>
                </div>
              </div>
            </div>
          )
        }

        {
          datosPersonales.length !== 0 && (
            <div className="card my-3 px-0">
              <div className="card-header bgArcopime"><label className='h6 my-0'>Datos personales</label></div>
              <div className="card-body">
                <div className="row">
                  <p className="col-4 mb-1">
                    <span className='tagLabel'>Nombre:</span> <span className='text-capitalize'>{datosPersonales.nombre}</span>
                  </p>
                  <p className='col-4 mb-1'>
                    <span className='tagLabel'>Fecha de nacimiento:</span> {momentFecha(datosPersonales.fecha_nacimiento)}
                  </p>
                  <p className='col-4 mb-1'>
                    <span className='tagLabel'>Email:</span> {datosPersonales.correo}
                  </p>
                </div>
                <div className="row">
                  <p className="col-4 mb-1">
                    <span className='tagLabel'>Apellido paterno:</span> <span className='text-capitalize'>{datosPersonales.apellido_pat}</span>
                  </p>
                  <p className='col-4 mb-1'>
                    <span className='tagLabel'>Grado de educación:</span> {filtrarGradoEducacion}
                  </p>
                  <p className='col-4 mb-1'>
                    <span className='tagLabel'>Teléfono:</span>

                    <span className="mx-1">+569</span> {datosPersonales.telefono}
                  </p>
                </div>
                <div className="row">
                  <p className="col-4 mb-1">
                    <span className='tagLabel'>Apellido materno:</span> <span className="text-capitalize">{datosPersonales.apellido_mat || 'No aplica'}</span>
                  </p>
                  <p className='col-4 mb-1'>
                    <span className='tagLabel'>Estado civil:</span> {filtrarEstadoCivil}
                  </p>
                  <p className='col-4 mb-1'>
                    <span className='tagLabel'>Género:</span> {filtrarSexo}
                  </p>
                </div>
                <div className="row">
                  <p className="col-4 mb-1">
                    <span className='tagLabel'>Rut:</span> {datosPersonales.identificacion}
                  </p>
                  <p className='col-4 mb-1'>
                    <span className='tagLabel'>País:</span> {filtrarPaisPersona}
                  </p>
                </div>
              </div>
            </div>
          )
        }

        {
          datosExtranjero.length !== 0 && (
            <div className="card my-3 px-0">
              <div className="card-header bgArcopime"><label className='h6 my-0'>Datos extranjería</label></div>
              <div className="card-body">
                <div className="row">
                  <p className="col-4">
                    <span className='tagLabel'>Tipo de visa:</span> {datosExtranjero.id_persona_tipo_visa === 1 ? "Definitiva" : "Temporaria"}
                  </p>
                  <p className="col-4">
                    <span className='tagLabel me-1'>Fecha emisión:</span>{momentFecha(datosExtranjero.fecha_visa_temporaria)}
                  </p>
                  <p className="col-4">
                    <span className='tagLabel me-1'>Fecha vencimiento:</span>{momentFecha(datosExtranjero.fecha_vencimiento_visa)}
                  </p>
                </div>
              </div>
            </div>
          )
        }
      </div>
      <div className="row">
        {
          datosDireccion.length !== 0 && (
            <div className="col-4 ps-0">
              <div className="card my-3 px-0">
                <div className="card-header bgArcopime"><label className='h6 my-0'>Dirección</label></div>
                <div className="card-body">
                  <div className="row">
                    <p className="mb-1">
                      <span className='tagLabel'>Dirección:</span> {datosDireccion.direccion}
                    </p>
                    <p className="mb-1">
                      <span className='tagLabel'>País:</span> {filtrarPaisDireccion}
                    </p>
                    <p className="mb-1">
                      <span className='tagLabel'>Región:</span> {filtrarRegion}
                    </p>
                    <p className="mb-1">
                      <span className='tagLabel'>Comuna:</span> {comunas}
                    </p>
                    <p className="mb-1">
                      <span className='tagLabel'>Número:</span> {datosDireccion.numero || 'No especifíca'}
                    </p>
                    <p className="mb-1">
                      <span className='tagLabel'>Casa/Depto/Parcela:</span> {datosDireccion.numero_departamento || 'No especifíca'}
                    </p>
                  </div>
                </div>
              </div>

            </div>
          )
        }
        <div className="col-4 ps-0">
          {
            datosPrevision.length !== 0 && (
              <div className="card my-3 px-0">
                <div className="card-header bgArcopime"><label className='h6 my-0'>Datos previsión</label></div>
                <div className="card-body">
                  <div className="row">
                    <p className="mb-1">
                      <span className='tagLabel'>Es una persona jubilada:</span> {datosPrevision.jubilado === 1 ? "Si" : "No"}
                    </p>
                    <p className="mb-1">
                      <span className='tagLabel'>Afp:</span> {filtrarAfp}
                    </p>
                    <p className="mb-1">
                      <span className='tagLabel'>{datoSalud.id !== 1 ? 'Isapre' : 'Plan salud'}:</span> {datoSalud.nombre}
                    </p>
                    <p className="mb-1">
                      {datoSalud.id !== 1 &&
                        <>
                          <span className='tagLabel'>Valor:</span> {datoSalud.valorPlan}
                        </>
                      }
                    </p>
                  </div>
                </div>
              </div>
            )
          }
        </div>
        <div className="col-4 px-0">
          {
            datosBancarios.length !== 0 && (
              <div className="card my-3 px-0">
                <div className="card-header bgArcopime"><label className='h6 my-0'>Datos bancarios</label></div>
                <div className="card-body">
                  <div className="row">
                    {datosBancarios.tipo_pago !== 3 ?
                      <>
                        <p className="mb-1">
                          <span className='tagLabel'>Banco:</span> {datosBancarios.nombre_banco}
                        </p>
                        <p className="mb-1">
                          <span className='tagLabel'>Tipo de cuenta:</span> {datosBancarios.nombre_tipo_cuenta}
                        </p>
                        <p className="mb-1">
                          <span className='tagLabel'>Número de cuenta:</span> {datosBancarios.numero_cuenta}
                        </p>
                      </>
                      :
                      <p className="mb-1">
                        <span className='tagLabel'>Tipo pago:</span> Servipag
                      </p>
                    }
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </div>
      {
        datosDocumento.length !== 0 && (
          <div className='row'>

            <div className="card my-3 px-0">
              <div className="card-header bgArcopime"><label className='h6 my-0'>Documentos</label></div>
              <div className="card-body">
                <div className='row'>
                  {
                    datosDocumento.map((documento, indx) => (
                      <div className='col-lg-3 col-sm-3' key={indx}>
                        <div className="card border-dark mb-3">
                          <div className="card-header">{documento.documento}</div>
                          <div className="card-body text-dark">
                            <a target="_blank" rel="noreferrer" href={documento.url} className="btn btn-outline-success">ver documento</a>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
        )
      }
    </div>
  )
}

export default VerVacateScreen