import React, { useEffect, useState } from "react";
import { obtenerPosicionesLaboralesApi } from "../../api/contratacion/datosLaboralesApi";
import { obtenerDatosTemporisAction } from "../../redux/datosLaboralesDucks";
import { useUnidadRolTemporis } from "../../hooks/datosLaborales";
import { getBusinessDaysAgo, momentFecha } from "../../utils/funciones";
import { mensajeSimple } from "../ui/sweetAlerts";
import { useNavigate } from 'react-router-dom';
import { PLAZOS } from "../../utils/Constants";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import ErrorInput from "../ui/ErrorInput";
import { getUno } from "../../api/apis";
import moment from "moment";

const FormDatosLaborales = ({ bloqueado, formik, verVacante }) => {
  const dispatch = useDispatch()
  const [arrPlanHorario, setArrPlanHorario] = useState([]);
  const [dataTemporis, setDataTemporis] = useState([]);
  const [posicionesLaborales, setPosicionesLaborales] = useState([])
  const [visibleRolTemporis, setvisibleRolTemporis] = useState(true)
  const { setRolTemporis } = useUnidadRolTemporis();
  const navigate = useNavigate();
  const fechaMaximaIngreso = moment().add(30, 'day').format('YYYY-MM-DD');
  const txtMensaje = 'El usuario con el que se ha logeado no mantiene posiciones, no puede continuar con el flujo, será redireccionado.';
  const fechaLaboralMinima = getBusinessDaysAgo(4);


  const getNumbersInString = (string) => {
    let tmp = string.split(" ");
    let map = tmp.map((current) => {
      if (!isNaN(parseFloat(current))) {
        return current;
      }
    });

    let numbers = map.filter(function (value) {
      return value !== undefined;
    });
    return numbers[0] || '45';
  }



  useEffect(() => {
    const dataPosiciones = async () => {
      const posiciones = await obtenerPosicionesLaboralesApi();
      if (posiciones.status === "success") {
        setPosicionesLaborales(posiciones.data);
      }
      else {
        mensajeSimple('info', txtMensaje, 9000);
        setTimeout(() => navigate("/consulta-rut"), 9000);
      }
    }
    dataPosiciones();
  }, []);

  const temporis = useSelector(store => store.datosLaborales.temporis);

  useEffect(() => {
    if (formik.values.datosPersonales.rut !== '') {
      localStorage.setItem('rut', formik.values.datosPersonales.rut)
    }
  }, [formik.values.datosPersonales.rut])

  const fetchData = async () => {
    const respTemporis = await dispatch(obtenerDatosTemporisAction(formik.values.datosLaborales.cargo))
    setvisibleRolTemporis(respTemporis?.mensaje ? false : true)
  }

  async function traerTemporis() {
    setRolTemporis(formik);
    formik.values.datosLaborales.planHorario = "";
    formik.setValues(formik.values);
    fetchData()
    if (formik.values.datosLaborales.cargo) {
      const cargo = JSON.parse(formik.values.datosLaborales.cargo)?.MOES_DESCRIPCION_POSICION;
      const localCodigoERP = JSON.parse(formik.values.datosLaborales.cargo)?.MOES_COD_SUB_DIVISION;

      const horario = getNumbersInString(cargo);

      const temporis = await getUno('temporis', { Localcodigoerp: localCodigoERP, plan_horario: horario }, null);

      formik.values.datosLaborales.tieneTemporis = (temporis.data.data_temporis) ? true : false;

      if (temporis.data.message) {
        const filtrado = temporis.data.data.filter((el) => String(el.Planhorarionombre).slice(3, 3 + horario.length) === horario)
        setArrPlanHorario(filtrado)
        if (filtrado.length === 0) {
          mensajeSimple('error', 'No se puede continuar con el proceso debido a que no existe plan de horario en temporis asignado a este cargo', 6000)
        }
      }
      else {
        setArrPlanHorario(temporis.data.data)
      }
    }
  }

  useEffect(() => {
    traerTemporis()
  }, [formik.values.datosLaborales.cargo])

  useEffect(() => {
    filtrarUnidadOrganizadora(formik.values.datosLaborales.planHorario);
  }, [formik.values.datosLaborales.planHorario])


  const filtrarUnidadOrganizadora = (value) => {
    if (temporis.data_temporis) {
      const dataTemporisFiltrada = temporis.data_temporis.filter(data => data.Planhorarionombre === value);
      setDataTemporis(dataTemporisFiltrada);
    }
  }

  const calcularFechaFinContrato = (ingreso, contrato) => {
    const formatoFecha = "YYYY-MM-DD";
    const diaDeIngreso = moment(ingreso).get("date");

    switch (contrato) {
      case "1":
        if (diaDeIngreso === 1) {
          const fecha = moment(ingreso)
            .add(1, "months")
            .subtract(1, "days")
            .format(formatoFecha);
          return fecha;
        }
        if (diaDeIngreso > 1) {
          const fecha = moment(ingreso)
            .add(1, "months")
            .endOf("month")
            .format(formatoFecha);
          return fecha;
        }

        break;
      case "2":
        if (diaDeIngreso === 1) {
          const fecha = moment(ingreso)
            .add(2, "months")
            .subtract(1, "days")
            .format(formatoFecha);
          return fecha;
        }
        if (diaDeIngreso > 1) {
          const fecha = moment(ingreso)
            .add(2, "months")
            .endOf("month")
            .format(formatoFecha);
          return fecha;
        }
        break;
      case "3":
        if (diaDeIngreso === 1) {
          const fecha = moment(ingreso)
            .add(3, "months")
            .subtract(1, "days")
            .format(formatoFecha);
          return fecha;
        }
        if (diaDeIngreso > 1) {
          const fecha = moment(ingreso)
            .add(3, "months")
            .endOf("month")
            .format(formatoFecha);
          return fecha;
        }
        break;
      case "4":
        return "9999-12-31";

      default:
        return null;

    }
  }

  useEffect(() => {
    if (formik.values.datosLaborales.fechaFinContrato && !formik.values.datosLaborales.fechaIngreso) {
      formik.values.datosLaborales.fechaFinContrato = "";
    }

    if (formik.values.datosLaborales.fechaIngreso && formik.values.datosLaborales.tipoContrato) {
      formik.values.datosLaborales.fechaFinContrato = calcularFechaFinContrato(
        formik.values.datosLaborales.fechaIngreso,
        formik.values.datosLaborales.tipoContrato
      );
    }
  }, [formik.values.datosLaborales.fechaIngreso, formik.values.datosLaborales.tipoContrato])

  return (
    <>
      <div className="row mt-3">
        <div className="col-12 col-sm-6 my-1">
          <label htmlFor="fechaIngreso">Fecha de ingreso *</label>
          <input
            // disabled={bloqueado}
            type="date"
            min={fechaLaboralMinima.format('yyyy-MM-dd')}
            max={fechaMaximaIngreso}
            name="datosLaborales.fechaIngreso"
            className="form-control"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.datosLaborales.fechaIngreso}
          />
          {formik.errors.datosLaborales !== undefined &&
            formik.touched.datosLaborales !== undefined && (
              <ErrorInput
                touched={formik.touched.datosLaborales.fechaIngreso}
                error={formik.errors.datosLaborales.fechaIngreso}
              />
            )}
        </div>
        <div className="col-12 col-sm-6 my-1">
          <label htmlFor="cargo">Cargo *</label>
          <select
            id="inputCargo"
            className="form-select"
            name="datosLaborales.cargo"
            aria-label="Default select example"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.datosLaborales.cargo}
          >
            <option value="">Seleccione</option>
            {
              posicionesLaborales &&
              Object.keys(posicionesLaborales).map((el, indx) => {
                return (
                  <>
                    <option className="bg-dark text-white" value='' key={indx}> {el} </option>
                    {
                      posicionesLaborales[el].map((ell, indxx) => {
                        return <option value={JSON.stringify(ell)} key={`${indxx}'-tt'`}>{ell.MOES_COD_POSICION} {ell.MOES_DESCRIPCION_POSICION}</option>
                      })
                    }
                  </>
                )
              })
            }
          </select>
          {formik.errors.datosLaborales !== undefined &&
            formik.touched.datosLaborales !== undefined && (
              <ErrorInput
                touched={formik.touched.datosLaborales.cargo}
                error={formik.errors.datosLaborales.cargo}
              />
            )}
        </div>
      </div>
      <div className="row">
        {
          formik.values.datosLaborales.cargo && (
            <div className="col-12 col-sm-6 my-1">
              <label htmlFor="planHorario">Plan horario *</label>
              <select
                // disabled={bloqueado}
                className="form-select"
                name="datosLaborales.planHorario"
                aria-label="Default select example"
                onChange={
                  (e) => {
                    const texto = e.target.options[e.target.selectedIndex].text;
                    setRolTemporis(formik);
                    formik.values.datosLaborales.planHorario = texto !== "Seleccione" ? texto : "";
                    formik.setValues(formik.values);
                  }
                }
                onBlur={formik.handleBlur}
                value={formik.values.datosLaborales.planHorario}
              >
                <option value="">Seleccione</option>
                {
                  (arrPlanHorario.length > 0) &&
                  arrPlanHorario.map((horario, indx) => (
                    <option value={horario.Planhorarionombre} key={`${indx}-j`}>
                      {horario.Planhorarionombre}
                    </option>
                  ))
                }
              </select>
              {formik.errors.datosLaborales !== undefined &&
                formik.touched.datosLaborales !== undefined && (
                  <ErrorInput
                    touched={formik.touched.datosLaborales.planHorario}
                    error={formik.errors.datosLaborales.planHorario}
                  />
                )}
            </div>
          )
        }

        {
          (formik.values.datosLaborales.planHorario &&
            formik.values.datosLaborales.cargo &&
            !temporis.datos_temporis &&
            dataTemporis.length > 0
          ) && (
            <div className="col-12 col-sm-6 my-1">
              <label htmlFor="unidadOrganizativaRolTemporis">Unidad organizativa - Rol Temporis *</label>
              <select
                className="form-select"
                name="datosLaborales.unidadOrganizativaRolTemporis"
                aria-label="Default select example"
                onChange={
                  (e) => {
                    formik.values.datosLaborales.unidadOrganizativaRolTemporis = e.target.value;
                    formik.setValues(formik.values);
                  }
                }
                onBlur={formik.handleBlur}
                value={formik.values.datosLaborales.unidadOrganizativaRolTemporis}
              >
                <option value="">Seleccione</option>
                {
                  dataTemporis.length !== 0 &&
                  dataTemporis.map((horario, indx) => (
                    <option value={JSON.stringify(horario)} key={indx}>{horario.Unidadorganizativa} - {horario.Cargogenericonombre}</option>
                  ))
                }
              </select>
              {formik.errors.datosLaborales !== undefined &&
                formik.touched.datosLaborales !== undefined && (
                  <ErrorInput
                    touched={formik.touched.datosLaborales.unidadOrganizativaRolTemporis}
                    error={formik.errors.datosLaborales.unidadOrganizativaRolTemporis}
                  />
                )}
            </div>
          )
        }
      </div>
      <div className="row">
        <div className="col-12 col-sm-6 my-1">
          <label htmlFor="tipoContato">Tipo de contrato *</label>
          <select
            className="form-select"
            name="datosLaborales.tipoContrato"
            aria-label="Default select example"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.datosLaborales.tipoContrato}
          >
            <option value="">Seleccione</option>
            {PLAZOS.map((el, indx) => <option value={el.value} key={indx}>{el.label}</option>)}
          </select>
          {formik.errors.datosLaborales !== undefined &&
            formik.touched.datosLaborales !== undefined && (
              <ErrorInput
                touched={formik.touched.datosLaborales.tipoContrato}
                error={formik.errors.datosLaborales.tipoContrato}
              />
            )}
        </div>
      </div>
      <div className="row">
        {formik.values.datosLaborales.fechaIngreso && formik.values.datosLaborales.tipoContrato && formik.values.datosLaborales.tipoContrato !== "4" && (
          <div className="col-12 col-sm-6 my-1">
            <label htmlFor="fechaRetiro">Fecha Fin Contrato</label>
            <input
              type="text"
              name="datosLaborales.fechaRetiro"
              className="form-control"
              readOnly
              value={momentFecha(formik.values.datosLaborales.fechaFinContrato)}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default FormDatosLaborales;
