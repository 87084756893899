import { BASE_URL_PRODUCCION } from "../../utils/Constants";


export const obtenerDatosSaludApi = async (idPersona) => {
    try {
        const params = {
            headers:{
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        } 
        const url = `${BASE_URL_PRODUCCION}/personasalud?idPersona=${idPersona}`
        const res = await fetch(url,params)
        const resJSON = await res.json()
        return resJSON
    } catch (error) {
        console.log("Error: ", error)
    }
}

export const guardarDatosSaludApi = async (data, idPersona) => {
    try {
        const url = `${BASE_URL_PRODUCCION}/personasalud`
        const { previsionSalud, certificadoCovid, certificadoDiscapacidad, planPactado } = data
        const params = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                id_institucion_salud: previsionSalud,
                valor_plan: planPactado,
                id_persona: idPersona,
                certificaco_covid: certificadoCovid,
                certificado_discapacidad: certificadoDiscapacidad
            })
        }

        const res = await fetch(url, params)
        let resJSON = await res.json()
        if(resJSON.hasOwnProperty('existe')){
            resJSON = await actualizarDatosSaludApi(data, idPersona)
        }
        return resJSON
    } catch (error) {
        console.log("Error :", error)
    }
}

export const actualizarDatosSaludApi = async (data, idPersona) => {
    try {
        const url = `${BASE_URL_PRODUCCION}/personasalud`
        const { previsionSalud, planPactado,certificadoCovid, certificadoDiscapacidad } = data
        const params = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                id_institucion_salud: previsionSalud,
                valor_plan: planPactado,
                id_persona: idPersona,
                certificaco_covid: certificadoCovid,
                certificado_discapacidad: certificadoDiscapacidad
            })
        }

        const res = await fetch(url, params)
        const resJSON = await res.json()
        return resJSON
    } catch (error) {
        console.log("Error :", error)
    }
}