import { BASE_URL_PRODUCCION } from "../utils/Constants";



//constantes
const OBTENER_BANCOS_EXITO = 'OBTENER_BANCOS_EXITO';

const OBTENER_TIPO_CUENTAS_EXITO = 'OBTENER_TIPO_CUENTAS_EXITO';

const dataInicial = {
    bancos: [],
    tipoCuentas : []
}

export default function datosBancariosReducer(state = dataInicial, action) {
    switch (action.type) {
        case OBTENER_BANCOS_EXITO:
            return { ...state, bancos: action.payload}
        case OBTENER_TIPO_CUENTAS_EXITO:
            return { ...state, tipoCuentas: action.payload}
        default:
            return state;
    }
}

// acciones 

// URL para el consumo de api
const BASE_URL = BASE_URL_PRODUCCION;
const params = {
    headers:{
        Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
}


export const obtenerBancosAction = () => async(dispatch, getState) => {
    try {
        const url = `${BASE_URL}/listado/banco`;
        const res = await fetch(url, params);
        const resJSON = await res.json();
        dispatch({
            type: OBTENER_BANCOS_EXITO,
            payload: resJSON.data
        })
    } catch (error) {
        console.log("error :", error)
    }
}

export const obtenerTipoDeCuentaBanco = () => async(dispatch, getState) => {
    try {
        const url = `${BASE_URL}/listado/bancotipocuenta`
        const res = await fetch(url,params)
        const resJSON = await res.json()
        dispatch({
            type: OBTENER_TIPO_CUENTAS_EXITO,
            payload: resJSON.data
        })
    } catch (error) {
        console.log("error :", error)
    }
}