import moment from "moment";
import React from "react";
import ErrorInput from "../ui/ErrorInput";
import { fechaMinimaHasta } from "../../utils/funciones";
import { useEffect } from "react";
import { useState } from "react";
import { momentFecha } from "../../utils/funciones";

const FormDatosNacionalidadExtranjera = ({ formik, txtTipoVisa }) => {
  const fechaHoy = new Date();
  return (
    <>
      <hr />
      {formik.values.tipoVisa === "2" && (
        <div className="alert alert-warning d-flex align-items-center" role="alert">
          <div>
            Si es Visa Sujeta a Contrato, no es posible generar la solicitud.
          </div>
        </div>
      )}
      <div className="row mt-3">
        <div className="col-12 col-sm-6 my-1">
          <label htmlFor="tipoVisa">Tipo de visa *</label>
          <select
            className="form-select"
            name="datosPersonaExtranjera.tipoVisa"
            aria-label="Default select example"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.datosPersonaExtranjera.tipoVisa}
          >
            <option value="">Seleccione</option>
            <option value="1">Definitiva</option>
            <option value="2">Temporaria</option>
          </select>

          {
            formik.errors.datosPersonaExtranjera !== undefined &&
            formik.touched.datosPersonaExtranjera !== undefined && (
              <ErrorInput
                touched={formik.touched.datosPersonaExtranjera.tipoVisa}
                error={formik.errors.datosPersonaExtranjera.tipoVisa}
              />
            )
          }

        </div>

        {(formik.values.datosPersonaExtranjera.tipoVisa !== "" && formik.values.datosPersonaExtranjera.tipoVisa !== 0) && (
          <div className="col-12 col-sm-6 my-1">
            <label htmlFor="visaTemporariaDesde">Visa {txtTipoVisa} desde *</label>
            <input
              type="date"
              min={moment(new Date()).subtract('y', 20).format('yyyy-MM-DD')}
              max={moment(new Date()).format('yyyy-MM-DD')}
              name="datosPersonaExtranjera.visaTemporariaDesde"
              className="form-control"
              onChange={
                (e) => { formik.setFieldValue('datosPersonaExtranjera.visaTemporariaDesde', e.target.value); }
              }
              onBlur={formik.handleBlur}
              value={formik.values.datosPersonaExtranjera.visaTemporariaDesde}
            />

            {
              formik.errors.datosPersonaExtranjera !== undefined &&
              formik.touched.datosPersonaExtranjera !== undefined && (
                <ErrorInput
                  touched={formik.touched.datosPersonaExtranjera.visaTemporariaDesde}
                  error={formik.errors.datosPersonaExtranjera.visaTemporariaDesde}
                />
              )
            }
          </div>
        )}
      </div>

      {(formik.values.datosPersonaExtranjera.tipoVisa !== "" && formik.values.datosPersonaExtranjera.tipoVisa !== 0) && (
        <div className="row mt-3">
          <div className="col-12 col-sm-6 my-1">
            <label htmlFor="visaTemporariaHasta">Visa {txtTipoVisa} hasta *</label>
            <input
              type="date"
              max="9999-12-31"
              min={fechaMinimaHasta(formik.values.datosPersonaExtranjera.visaTemporariaDesde)}
              name="datosPersonaExtranjera.visaTemporariaHasta"
              className="form-control"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.datosPersonaExtranjera.visaTemporariaHasta}
            />
            {
              formik.errors.datosPersonaExtranjera !== undefined &&
              formik.touched.datosPersonaExtranjera !== undefined && (
                <ErrorInput
                  touched={formik.touched.datosPersonaExtranjera.visaTemporariaHasta}
                  error={formik.errors.datosPersonaExtranjera.visaTemporariaHasta}
                />
              )
            }
          </div>
        </div>
      )}
      {moment(formik.values.datosPersonaExtranjera.visaTemporariaHasta).diff(
        moment(formik.values.datosPersonaExtranjera.visaTemporariaDesde),
        "month"
      ) < 3 && (
          <div className="alert alert-warning d-flex align-items-center my-3" role="alert">
            <div>
              La vigencia de visa es menor a 3 meses, en el formulario de documentos se debe adjuntar documento de trámite de permanencia definitiva y/o renovación de visa emitido por extranjería.
            </div>
          </div>
        )}
    </>
  );
};

export default FormDatosNacionalidadExtranjera;
