import React from "react";

const ErrorInput = ({ touched, error }) => {
  if (touched && error) {
    return (
      <div className="mt-1">
        <span className="badge rounded-pill bg-danger">{error}</span>
      </div>
    );
  }
  return null;
};

export default ErrorInput;
