import { BASE_URL_PRODUCCION } from "../../utils/Constants"

export const obtenerDatosPrevisioApi = async (idPersona) => {
    try {
        const params = {
            headers:{
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        } 
        const url = `${BASE_URL_PRODUCCION}/personaprevision?idPersona=${idPersona}`
        const res = await fetch(url,params)
        const resJSON = await res.json()
        return resJSON
    } catch (error) {
        console.log("Error: ", error)
    }
}

export const guardarDatosPrevisionApi = async (datos, idPersona) => {
    try {
        const url = `${BASE_URL_PRODUCCION}/personaprevision`
        const { jubilado, previsionSocial } = datos
        const params = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                id_afp: previsionSocial,
                id_persona: idPersona,
                jubilado: jubilado
            })
        }

        const res = await fetch(url, params)
        let resJSON = await res.json(datos, idPersona)

        if(resJSON.hasOwnProperty('existe')){
            resJSON = await actualizarDatoPrevisionApi(datos, idPersona)
        }

        return resJSON
    } catch (error) {
        console.log("Error :", error)
    }

}

export const actualizarDatoPrevisionApi = async (datos, idPersona) => {
    try {
        const url = `${BASE_URL_PRODUCCION}/personaprevision`
        const { jubilado, previsionSalud, previsionSocial, planPactado } = datos
        const params = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                id_afp: previsionSocial,
                id_persona: idPersona,
                jubilado: jubilado,
                id_prevision_salud: previsionSalud,
                valor_plan_salud_pactado: planPactado
            })
        }

        const res = await fetch(url, params)
        const resJSON = await res.json()
        return resJSON
    } catch (error) {
        console.log("Error :", error)
    }
}