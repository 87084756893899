import { BASE_URL_PRODUCCION } from "../../utils/Constants"
import { validaResponse } from "./validacionResponse";
import { mensajeSimple } from "../../components/ui/sweetAlerts";

export const guardarVacanteApi = async (idPersona, trabajadorNuevo, guardadoTemporal = 0) => {
  try {
    const url = `${BASE_URL_PRODUCCION}/solicitud`
    const params = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      method: 'POST',
      body: JSON.stringify({
        id_persona: idPersona,
        trabajador_nuevo: trabajadorNuevo, // se refiere a si el proceso ya se encuentra en proceso o se esta ingresando los datos de un trabjador nuevo sin necesidad de ser aprobado por rrhh 
        guardado_temporal: guardadoTemporal
      })
    };
    const res = await fetch(url, params)
    const resJSON = await res.json()

    return resJSON;
  } catch (error) {
    console.log("Error: ", error)
  }
}

export const guardadoParcialApi = async (idPersona, trabajadorNuevo, guardadoTemporal = 0) => {
  try {
    const url = `${BASE_URL_PRODUCCION}/guardadoparcial`
    const params = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      method: 'POST',
      body: JSON.stringify({
        id_persona: idPersona,
        trabajador_nuevo: trabajadorNuevo, // se refiere a si el proceso ya se encuentra en proceso o se esta ingresando los datos de un trabjador nuevo sin necesidad de ser aprobado por rrhh 
        guardado_temporal: guardadoTemporal
      })
    };
    const res = await fetch(url, params)
    const resJSON = await res.json()

    return resJSON;
  } catch (error) {
    console.log("Error: ", error)
  }
}

export const guardarSolicitudReingreso = async (idPersona) => {
  try {
    const url = `${BASE_URL_PRODUCCION}/solicitudreingreso`
    const params = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      method: 'POST',
      body: JSON.stringify({
        id_persona: idPersona
      })
    };
    const res = await fetch(url, params)
    const resJSON = await res.json()

    return resJSON;
  } catch (error) {
    console.log("Error: ", error)
  }
}

export const obtenerVacanteApi = async (idPersona, idSolicitud) => {
  try {
    const params = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    }
    const url = `${BASE_URL_PRODUCCION}/vacante?idPersona=${idPersona}&idSolicitud=${idSolicitud}`
    const res = await fetch(url, params)
    const data = await validaResponse(res)

    return data
  } catch (error) {
    console.log("Error :", error)
  }
}


export const verificaPosicion = async (idPosicion) => {
  try {
    const params = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    }
    const url = `${BASE_URL_PRODUCCION}/verificaposicion?idPosicionSap=${idPosicion}`
    const res = await fetch(url, params)
    const data = await res.json()
    return data
  } catch (error) {
    console.log("Error :", error)
  }
}

export const obtenerListaVacantes = async (page, totalPage) => {
  try {
    const params = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    }
    const url = `${BASE_URL_PRODUCCION}/solicitudproceso?page=${page}&total_page=${totalPage}`;

    const response = await fetch(url, params)
    const responseJSON = await response.json();
    return responseJSON
  } catch (error) {
    console.log("error :", error)
  }
}

export const obtenerListaVacantesAprobadas = async (page, totalPage) => {

  try {
    const params = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    }
    const url = `${BASE_URL_PRODUCCION}/solicitudaprouser?page=${page}&total_page=${totalPage}`;

    const response = await fetch(url, params)

    if (response.status === 500) { mensajeSimple('error', 'La sesión ha caducado.', 3000); return; }

    const responseJSON = await response.json();
    return responseJSON
  } catch (error) {
    console.log("error :", error)
  }
}

export const obtenerSolicitudesReingreso = async (page, totalPage) => {

  try {
    const params = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    }
    const url = `${BASE_URL_PRODUCCION}/solicitudreingreso?page=${page}&total_page=${totalPage}`;
    const response = await fetch(url, params);

    if (response.status === 500) { mensajeSimple('error', 'La sesión ha caducado.', 3000); }

    const responseJSON = await response.json();
    return responseJSON
  } catch (error) {
    console.log("error :", error)
  }
}


export const aprobarSolicitudVacanteApi = async (idSolicitud, body = {}) => {
  try {
    const url = `${BASE_URL_PRODUCCION}/solicitudproceso?idSolicitud=${idSolicitud}`
    const params = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      method: 'PUT',
      body: JSON.stringify(body)
    }
    const res = await fetch(url, params)
    const resJSON = await res.json()
    return resJSON
  } catch (error) {
    console.log("Error: ", error)
  }
}

export const bloquearPosicion = async (idVacante) => {
  try {
    const url = `${BASE_URL_PRODUCCION}/bloqueoposicion?idVacante=${idVacante}`
    const params = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      method: 'POST'
    }
    const res = await fetch(url, params)
    const resJSON = await res.json()
    return resJSON

  } catch (error) {
    console.log("Error: ", error)
  }
}

export const declinarSolicitudVacanteApi = async (idSolicitud, observacion) => {
  try {
    const url = `${BASE_URL_PRODUCCION}/solicitudproceso?idSolicitud=${idSolicitud}`
    const params = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      method: 'Delete',
      body: JSON.stringify({
        observaciones: observacion,
        rechazo: true,
        rechazo_temporal: false
      })
    }
    const res = await fetch(url, params)
    const resJSON = await res.json()
    return resJSON
  } catch (error) {
    console.log("Error: ", error)
  }
}



