export const validaResponse = async(response) =>{


    if(response.status === 401){
        console.log('token expirado')
    }

    if(response.status === 400){
        console.log('peticion incorrecta')
    }

    if(response.status === 403){
        console.log('No autorizado')
    }

    if(response.status === 404){
        console.log('No encontrado')
    }

    if(response.status === 200 || response.status === 201){
        const data = await response.json()
        if(data.status === 'success'){
           return data
        }
    }
    return false
}