import { BASE_URL_PRODUCCION} from "../utils/Constants";

//constantes
const OBTENER_NIVELES_EDUCACION_EXITO = 'OBTENER_NIVELES_EDUCACION_EXITO';

const dataInicial = {
    nivelesEducacionales: []
}

// reducer 
export default function datosEducacionalesReducer(state = dataInicial, action) {
    switch (action.type) {
        case OBTENER_NIVELES_EDUCACION_EXITO:
            return {...state, nivelesEducacionales: action.payload}
        default:
            return state;
    }
}

//acciones 
// URL para el consumo de api
const BASE_URL = BASE_URL_PRODUCCION;
const params = {
    headers:{
        Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
}

export const obtenerNivelesEducacionalesAction = () => async(dispatch, getState) => {
    try {
        const url = `${BASE_URL}/listado/educacion`;
        const response = await fetch(url,params)
        const result = await response.json()
        dispatch({
            type: OBTENER_NIVELES_EDUCACION_EXITO,
            payload: result.data
        })
    } catch (error) {
        console.log("error :", error)
    }
}