import { useState } from "react";

const objRequeridos = {
  1: 'curriculum',
  2: 'cedula',
  3: 'antecedentes',
  4: 'residencia',
  5: 'afp',
  6: 'salud',
  7: 'visa',
  8: 'discapacidad',
  9: 'covid',
  10: 'jubilacion',
}

export const useDocumentos = () => {
  const errObj = {};
  const [documentosErr, setErrors] = useState({});

  const validateDocumentos = obj => {
    const { curriculum, cedula, antecedentes, residencia, afp, salud, visa, jubilacion, covid, certificadoCovid, nacionalidad, jubilado } = obj;

    if ((!covid) && certificadoCovid === "1") { errObj.covid = "Requerido"; }
    if (!curriculum) { errObj.curriculum = "Requerido"; }
    if (!cedula) { errObj.cedula = "Requerido"; }
    if (!antecedentes) { errObj.antecedentes = "Requerido"; }
    if (!residencia) { errObj.residencia = "Requerido"; }
    if (!afp) { errObj.afp = "Requerido"; }
    if (!salud) { errObj.salud = "Requerido"; }
    if (!jubilacion && jubilado === "1") { errObj.jubilacion = "Requerido"; }
    if (parseInt(nacionalidad) !== 1 && !visa) { errObj.visa = "Requerido"; }
    setErrors(errObj);
  }

  const objDocumentos = obj => {
    const arrRequeridos = obj.map((el) => {
      const llvar = objRequeridos[el.id_tipo_documento];
      return ({ [llvar]: el.url });
    });
    const objFinal = arrRequeridos.reduce((acc, curr) => {
      const [key, value] = Object.entries(curr)[0];
      acc[key] = value;
      return acc;
    }, {});
    return (objFinal);
  }

  return { documentosErr, validateDocumentos, objDocumentos }
}