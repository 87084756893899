import { mensajeSimple, modalCargando } from "./ui/sweetAlerts";
const exportarJsonExcel = require("js-export-excel");

export const BtnDescargarExcel = ({ejecutarFn}) => {
  const excelSolicitudesSAP = async () => {
    modalCargando('Preparando Excel...');
    const {
      nombreArchivo,
      columNombresTabla,
      datosTabla,
      columMuestreo,
      anchoColumnas
    } = await ejecutarFn();

    let objConfigExcel = {};
    objConfigExcel.fileName = nombreArchivo;
    objConfigExcel.datas = [
      {
        sheetHeader: columNombresTabla,
        sheetData: datosTabla,
        sheetName: nombreArchivo.split('_')[1],
        sheetFilter: columMuestreo,
        columnWidths: anchoColumnas,
      },
    ];
    // return
    let excelArchivo = new exportarJsonExcel(objConfigExcel);
    excelArchivo.saveExcel();
    mensajeSimple('success', 'Listo!', 1000); 
  }


  return <button className="btnExcel" onClick={excelSolicitudesSAP}>Descargar Excel</button>
  // return <button className="btnExcel" disabled={botonDisabled} onClick={excelSolicitudesSAP}>
  //           {nombreBoton}
  //         </button>
  // return <button className="btnExcel" style={`disabled:${botonActivo == true ? 'disabled' : ''}`} onClick={excelSolicitudesSAP}>{nombreBoton}</button>
}
