import { useState, useContext } from "react";
import { mensajeSimple } from "../components/ui/sweetAlerts";
import { DataContext } from "../contextData/contextData";
import { momentFecha } from "../utils/funciones";
import { getUno } from "../api/apis";

export const usePagina = () => {
  const { page, setearPagina } = useContext(DataContext);
  const [lista, setLista] = useState([]);
  const [listaExcel, setListaExcel] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);

  function setPage(nro) { setearPagina(nro) }

  const llamarPagina = async (nombreApi, body) => {
    setLoading(true);
    const resp = await getUno(nombreApi, null);
    setLoading(false);

    if (resp === 500 || resp === 401) { mensajeSimple('error', 'La sesión ha caducado.', 3000); }

    if (resp.data.status === 'info') { mensajeSimple(resp.data.status, resp.data.message, 2000); }
    else if (resp.data.success || resp.status === 200) {
      setLista(resp.data.data.map((el) => {
        return {
          ...el,
          fecha_registro: momentFecha(el.fecha_registro),
          fecha_ingreso: el.fecha_ingreso !== "No aplica" ? momentFecha(el.fecha_ingreso) : "No aplica",
          rechazada: el.rechazada === true ? 'SI' : 'NO'
        }
      }));
    }
  }

  const llamarPaginaResumen = async (nombreApi, body) => {
    setLoading(true);
    const resp = await getUno(nombreApi, body, null);
    console.log(resp)
    setLoading(false);

    if (resp === 500 || resp === 401) { mensajeSimple('error', 'La sesión ha caducado.', 3000); }

    if (resp.data.status === 'info') { mensajeSimple(resp.data.status, resp.data.message, 2000); }
    else if (resp.data.success || resp.status === 200) {
      setLista(resp.data.data);
      
      setTotalRows(resp.data.paginacion.total);
    }
  }

  const llamarSolicitudesExcel = async (nombreApi, body) => {
    
    const resp = await getUno(nombreApi, body, null);
    if (resp.status === 500 || resp.status === 401) { mensajeSimple('error', 'La sesión ha caducado.', 3000); }
    
    if (resp.data.status === 'info') { mensajeSimple(resp.data.status, resp.data.message, 2000); }
    else if (resp.data.status === 'success' || resp.status === 200) {
      
      setListaExcel(resp.data.data);
      //setTotalRows(resp.data.paginacion.total);
    }
  }

  return {
    page, setPage,
    loading, lista, listaExcel,
    llamarPagina, llamarPaginaResumen, llamarSolicitudesExcel,
    totalRows
  }
}