import { useState } from "react";

export const useDatosPrevision = () => {
  let errObj = {};
  const [datosPrevisionErr, setErrors] = useState({});

  const validatePrevision = obj => {
    const { jubilado, previsionSalud, previsionSocial, planPactado } = obj;

    if (!jubilado) { errObj.jubilado = "Requerido"; }
    if (!previsionSalud) { errObj.previsionSalud = "Requerido"; }
    if (!previsionSocial) { errObj.previsionSocial = "Requerido"; }
    if (`${previsionSalud}` !== "1" && !planPactado) { errObj.planPactado = "Requerido"; }

    const regexp = /^\d+(\.\d{1,4})?$/;
    if (`${previsionSalud}` !== "1" && (planPactado === '0' || planPactado === '0,')) {
      errObj.planPactado = "Ingrese corretamente el valor de la UF";
    }
    setErrors(errObj);
  }

  return { datosPrevisionErr, validatePrevision }
}