import React from "react";
import { fileSize } from "../../forms/contratacion/validation";
import { LabelTamanioMax } from "../ui/labels";

const FormDocumentos = ({ formik }) => {
  const setInputFileEstado = (nombreInput, e) => {
    const input = `datosDocumentos.${nombreInput}`;
    if (e.target.files.length > 0) {
      if (!fileSize(e.target.files[0].size)) {
        e.target.value = '';
        formik.setFieldValue(input, null);
        return;
      }
    }
    formik.setFieldValue(input, e.target.value !== '' ? e.currentTarget.files : null);
  }

  return (
    <>
      <div className="row mt-3">
        <div className="col-12 col-sm-6 mt-3">
          <div className="mb-1 d-flex justify-content-between">
            <label htmlFor="curriculum">Currículum vitae *</label>
            {!formik.values.datosDocumentos.curriculum && <LabelTamanioMax />}
          </div>
          <input
            type="file"
            className="form-control"
            accept=".pdf, .png, .jpg"
            name="datosDocumentos.curriculum"
            onChange={e => setInputFileEstado("curriculum", e)}
            onBlur={formik.handleBlur}
          />
        </div>
        <div className="col-12 col-sm-6 mt-3">
          <div className="mb-1 d-flex justify-content-between">
            <label htmlFor="cedula">Cédula de identidad *</label>
            {!formik.values.datosDocumentos.cedula && <LabelTamanioMax />}
          </div>
          <input
            type="file"
            className="form-control"
            accept=".pdf, .png, .jpg"
            name="datosDocumentos.cedula"
            onChange={e => setInputFileEstado("cedula", e)}
            onBlur={formik.handleBlur}
          />
        </div>
        <div className="col-12 col-sm-6 mt-3">
          <div className="mb-1 d-flex justify-content-between">
            <label htmlFor="antecedentes">Certificado de antecedentes *</label>
            {!formik.values.datosDocumentos.antecedentes && <LabelTamanioMax />}
          </div>
          <input
            type="file"
            accept=".pdf, .png, .jpg"
            className="form-control"
            name="datosDocumentos.antecedentes"
            onChange={e => setInputFileEstado("antecedentes", e)}
            onBlur={formik.handleBlur}
          />
        </div>
        <div className="col-12 col-sm-6 mt-3">
          <div className="mb-1 d-flex justify-content-between">
            <label htmlFor="residencia">Residencia *</label>
            {!formik.values.datosDocumentos.residencia && <LabelTamanioMax />}
          </div>
          <input
            type="file"
            accept=".pdf, .docx, .png, .jpg"
            name="datosDocumentos.residencia"
            className="form-control"
            onChange={e => setInputFileEstado("residencia", e)}
            onBlur={formik.handleBlur}
          />
        </div>
        <div className="col-12 col-sm-6 mt-3">
          <div className="mb-1 d-flex justify-content-between">
            <label htmlFor="afp">Certificado de AFP *</label>
            {!formik.values.datosDocumentos.afp && <LabelTamanioMax />}
          </div>
          <input
            type="file"
            name="datosDocumentos.afp"
            className="form-control"
            accept=".pdf, .png, .jpg"
            onChange={e => setInputFileEstado("afp", e)}
            onBlur={formik.handleBlur}
          />
        </div>
        <div className="col-12 col-sm-6 mt-3">
          <div className="mb-1 d-flex justify-content-between">
            <label htmlFor="salud">Certificado de fonasa o isapre *</label>
            {!formik.values.datosDocumentos.salud && <LabelTamanioMax />}
          </div>
          <input
            type="file"
            accept=".pdf, .png, .jpg"
            name="datosDocumentos.salud"
            className="form-control"
            onChange={e => setInputFileEstado("salud", e)}
            onBlur={formik.handleBlur}
          />
        </div>

        {parseInt(formik.values.datosPersonales.nacionalidad) !== 1 && (
          <div className="col-12 col-sm-6 mt-3" id="certificadoVisa">
            <div className="mb-1 d-flex justify-content-between">
              <label htmlFor="visa">Certificado de Visa *</label>
              {!formik.values.datosDocumentos.visa && <LabelTamanioMax />}
            </div>
            <input
              type="file"
              accept=".pdf, .jpeg, .jpg, .png"
              name="datosDocumentos.visa"
              className="form-control"
              onChange={e => setInputFileEstado("visa", e)}
              onBlur={formik.handleBlur}
            />
          </div>
        )}

        {
          parseInt(formik.values.datosPrevision.certificadoDiscapacidad) === 1 && (
            <div className="col-12 col-sm-6 mt-3" id="certidicadoDiscapacidad">
              <div className="mb-1 d-flex justify-content-between">
                <label htmlFor="discapacidad">Certificado de discapacidad *</label>
                {!formik.values.datosDocumentos.discapacidad && <LabelTamanioMax />}
              </div>
              <input
                type="file"
                accept=".pdf, .jpeg, .jpg, .png"
                name="datosDocumentos.discapacidad"
                className="form-control"
                onChange={e => setInputFileEstado("discapacidad", e)}
                onBlur={formik.handleBlur}
              />
            </div>
          )}
        {
          parseInt(formik.values.datosPrevision.certificadoCovid) === 1 && (
            <div className="col-12 col-sm-6 mt-3" id="certificadoCovid">
              <div className="mb-1 d-flex justify-content-between">
                <label htmlFor="covid">Certificado seguro COVID *</label>
                {!formik.values.datosDocumentos.covid && <LabelTamanioMax />}
              </div>
              <input
                type="file"
                name="datosDocumentos.covid"
                accept=".pdf, .jpeg, .jpg, .png"
                className="form-control"
                onChange={e => setInputFileEstado("covid", e)}
                onBlur={formik.handleBlur}
              />
            </div>
          )}
        {
          parseInt(formik.values.datosPrevision.jubilado) === 1 && (
            <div className="col-12 col-sm-6 mt-3" id="certificadoJubilacion">
              <div className="mb-1 d-flex justify-content-between">
                <label htmlFor="jubilacion">Certificado de jubilación *</label>
                {!formik.values.datosDocumentos.jubilacion && <LabelTamanioMax />}
              </div>
              <input
                type="file"
                name="datosDocumentos.jubilacion"
                accept=".pdf, .png, .jpg"
                className="form-control"
                onChange={e => setInputFileEstado("jubilacion", e)}
                onBlur={formik.handleBlur}
              />
            </div>
          )}
      </div>
    </>
  );
};

export default FormDocumentos;
