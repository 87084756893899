import React, { useState, useEffect } from 'react';
import './pageNotFound.css';

export const PageNotFound = () => {
  const [firstDigit, setFirstDigit] = useState(0);
  const [secondDigit, setSecondDigit] = useState(0);
  const [thirdDigit, setThirdDigit] = useState(0);

  const randomNum = () => Math.floor(Math.random() * 9) + 1;

  useEffect(() => {
    let i = 0;
    const time = 30;
    const maxIterations = 100; // Número máximo de iteraciones

    const loop1 = setInterval(() => {
      if (i >= maxIterations) {
        clearInterval(loop1);
        setFirstDigit(4); // Asegurar que el último dígito sea 4
      } else {
        setFirstDigit(randomNum());
      }
      i++;
    }, time);

    const loop2 = setInterval(() => {
      if (i >= (maxIterations * 2) / 3) { // Aproximadamente dos tercios del camino
        clearInterval(loop2);
        setSecondDigit(0); // Asegurar que el segundo dígito sea 0
      } else {
        setSecondDigit(randomNum());
      }
    }, time);

    const loop3 = setInterval(() => {
      if (i >= maxIterations / 3) { // Aproximadamente un tercio del camino
        clearInterval(loop3);
        setThirdDigit(4); // Asegurar que el primer dígito sea 4
      } else {
        setThirdDigit(randomNum());
      }
    }, time);

    // Limpieza al desmontar
    return () => {
      clearInterval(loop1);
      clearInterval(loop2);
      clearInterval(loop3);
    };
  }, []);

  return (
    <div className="error_new">
      <div className="container-fluid">
        <div className="col-xs-12 ground-color text-center">
          <div className="container-error-404">
            <div className="clip"><div className="shadow"><span className="digit">{thirdDigit}</span></div></div>
            <div className="clip"><div className="shadow"><span className="digit">{secondDigit}</span></div></div>
            <div className="clip"><div className="shadow"><span className="digit">{firstDigit}</span></div></div>
            <div className="msg">OH!<span className="triangle"></span></div>
          </div>
          <h2 className="h1">La página que buscas no existe.</h2>
        </div>
      </div>
    </div>
  );
};

