import { useState } from "react";
import { getBusinessDaysAgo } from "../../utils/funciones";
import moment from "moment";

export const useDatosLaborales = () => {
  const [datosLaboralesErr, setErrors] = useState({});
  const fechaLaboralMinima = getBusinessDaysAgo(5);
  const fechaMaximaIngreso = moment().add(1, 'month');
  
  const validateLaborales = obj => {
    let errObj = {};
    const { fechaIngreso, cargo, planHorario, tipoContrato, tieneTemporis, unidadOrganizativaRolTemporis } = obj;

    if (!fechaIngreso) { errObj.fechaIngreso = "Requerido"; }

    if (moment(fechaIngreso).format('YYYY-MM-DD') < fechaLaboralMinima.format('YYYY-MM-DD')) {
      errObj.fechaIngreso = `No se puede ingresar una fecha con más de 5 días de retraso`;
    }

    const fechaIngreso_ = new Date(fechaIngreso);
    const fechaMaximaIngreso_ = new Date(fechaMaximaIngreso);
    if (fechaIngreso_ > fechaMaximaIngreso_) {
      errObj.fechaIngreso = `No puede solicitar ingreso de personal con más de 30 días de anticipación`;
    }

    if (!cargo) { errObj.cargo = "Requerido"; }
    if (!planHorario) { errObj.planHorario = "Requerido"; }

    if (tieneTemporis && !unidadOrganizativaRolTemporis) { errObj.unidadOrganizativaRolTemporis = "Requerido"; }

    if (!tipoContrato) { errObj.tipoContrato = "Requerido"; }
    else if (tipoContrato && !fechaIngreso) { errObj.tipoContrato = "Es necesario la fecha de ingreso"; }

    setErrors(errObj);
  }

  return { datosLaboralesErr, validateLaborales }
}