import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import datosBancariosReducer from "./datosBancariosDucks";
import datosEducacionalesReducer from "./datosEducacionalesDucks";
import datosLaboralesReducer from "./datosLaboralesDucks";
import datosPersonalesReducer from "./datosPersonalesDucks";
import datosPersonaSolicitanteReducer from "./datosPersonaSolicitanteDucks";
import datosPrevisionalesReducer from "./datosPrevisionalesDucks";          
import datosVacantesReducer from "./datosVacantesDucks";
import datosZonasReducer from "./datosZonasDucks";

//llama a los archivos para acceder a los reducer
const rootReducer = combineReducers({   
    datosLaborales: datosLaboralesReducer,
    datosVacantes: datosVacantesReducer,
    datosZonas: datosZonasReducer,
    datosPersonales: datosPersonalesReducer,
    datosEducacionales: datosEducacionalesReducer,
    datosBancarios: datosBancariosReducer,
    datosPrevisionales: datosPrevisionalesReducer,
    datosPersonaSolicitante: datosPersonaSolicitanteReducer
})

//configura la extension del navegador para ver el comportamiento de los datos con redux
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//funcion que configura el store
export default function generateStore(){
    const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))
    return store;
}