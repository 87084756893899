import { useContext, useState } from "react";
import { DataContext } from "../contextData/contextData";

export const useUnidadRolTemporis = () => {
  const [rolTemporis, setRol] = useState(false);

  function getNumero() {
    const elm = document.querySelector('#inputCargo');
    const texto = elm.options[elm.selectedIndex].text;
    return texto.substr(texto.length - 1, 1).replace(/[A-Z]/g, '');
  }

  function setRolTemporis() {
    const resp = getNumero();
    setRol(resp === '' ? false : true);
  }

  return { rolTemporis, setRolTemporis }
}

export const useReingreso = () => {
  const { reingresa, cambioReingresa } = useContext(DataContext);

  function setReingreso(booleanData) { cambioReingresa(booleanData); }

  return { reingresa, setReingreso }
}